.App {
  display: grid;
}
h1{
  color: azure;
}
h2{
  color: aliceblue;
}
.app-wrapper{
  justify-self: center;
  padding: 20px;
  background-image: url('Images/background2.jpg');
  background-repeat: repeat-y;
min-height: 980px;
  width: 1300px;
  height: auto;
}
