.tableList{
    display: grid;
    gap: 10px;
    color: black;
    padding-top: 20px;
}

.tableStyle{
    background-color: rgba(0, 73, 147, 0.8);
    opacity: 0.8;
}
.rowTableStyle{
    color: azure;
    background-color: rgba(0, 43, 77, 0.79);
}
.rowTableStyle:hover{
    color: black;
}
.iconsBlock{
    display: grid;
    grid-gap: 20px;
    justify-self: right;
    grid-auto-flow: column;
    padding: 10px;
    width: fit-content;
    border: azure 2px solid;
    border-radius: 10px;
    background-color: rgba(0, 43, 77, 0.79);
}

.iconsActiveEdit{
   font-size: 30px;
    cursor: pointer;
    color: azure;
}
.iconsActiveDelete{
    font-size: 30px;
    cursor: pointer;
    color:red;
}
.iconsDisabled{
    font-size: 20px;
    color: #d1cfcf;
}